// eslint-disable-next-line import/prefer-default-export
export const indexBy = (items, propertyName, { first = false } = {}) => {
  const objectOfIndices = {};
  items.forEach(item => {
    const index = item[propertyName];
    if (!first || !objectOfIndices[index]) {
      objectOfIndices[index] = item;
    }
  });
  return objectOfIndices;
};
