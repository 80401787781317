import orderApiPromise from '../promise/orderApi';
import observify from '../util/observify';

const editExistingOrder = observify(orderApiPromise.editExistingOrder);
const getAdpMenuFromApi = observify(orderApiPromise.getAdpMenuFromApi);
const getMenuFromApi = observify(orderApiPromise.getMenuFromApi);
const getNutrition = observify(orderApiPromise.getNutrition);
const getOrderDates = observify(orderApiPromise.getOrderDates);
const getOrderTimes = observify(orderApiPromise.getOrderTimes);
const initiateOrder = observify(orderApiPromise.initiateOrder);
const requestPaymentApi = observify(orderApiPromise.requestPaymentApi);
const updateOrder = observify(orderApiPromise.updateOrder);
const autocompleteAddressFromApi = observify(orderApiPromise.autocompleteAddressFromApi);
const validateAddressFromApi = observify(orderApiPromise.validateAddressFromApi);
const validateDeliveryRange = observify(orderApiPromise.validateDeliveryRange);

export default {
  editExistingOrder,
  getAdpMenuFromApi,
  getMenuFromApi,
  getNutrition,
  getOrderDates,
  getOrderTimes,
  initiateOrder,
  requestPaymentApi,
  updateOrder,
  autocompleteAddressFromApi,
  validateAddressFromApi,
  validateDeliveryRange
};
