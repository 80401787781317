export const statusCodes = {
  600: {
    message:
      'An item in your cart was not available for {DESTINATION} at this location. The item has been removed from the cart. [DISMISSIBLE]',
    type: 'warning'
  },
  1301: {
    message: 'Uh oh! Something went wrong. Please try again or contact support.',
    type: 'error'
  },
  1311: {
    message: 'This store does not accept Delivery orders. Please select Pickup.',
    type: 'error'
  },
  1314: {
    message:
      'Cannot order breakfast items after breakfast hours. Please select a new time, or remove breakfast items.',
    type: 'error'
  },
  1320: {
    message:
      'The restaurant POS server may be offline. Please ensure that it is operational and try again.',
    type: 'error'
  },
  1378: {
    message:
      'This item has been locked by the restaurant operator. Please remove it from the cart to proceed.',
    type: 'error'
  },
  1414: {
    message: 'No time slots available for the selected date. Please select a new date and time.',
    type: 'error'
  },
  1416: {
    message:
      'No available breakfast time slots found for day selected. Please select a new date and time, or remove breakfast items. [DISMISSIBLE]',
    type: 'error'
  },
  1420: {
    message:
      'An item in your cart is not available for the selected destination at this location. Please remove the item from the cart.',
    type: 'error'
  },
  1421: {
    message:
      'An item in your cart was not available for the selected destination at this location. The item has been removed from the cart. [DISMISSIBLE]',
    type: 'warning'
  },
  1430: {
    message:
      'Oops! Something went wrong with editing your order. Please refresh the page and try to edit again, or edit the order on the POS.',
    type: 'error'
  },
  1433: {
    message: 'Each favorite name must be a unique. Please try a different name.',
    type: 'error'
  }
};

export const deviceErrorMessages = {
  OFFLINE: {
    type: 'error',
    message:
      "Your device is not connected to the internet. Please check your network connection in your device's settings."
  }
};

export const DOPErrorMessages = {
  DESTINATION_INVALID:
    'Error: This location does not accept orders for the selected destination. Please change location or destination.'
};

export const DOPWarningMessages = {
  DESTINATION_INVALID:
    'Warning: This location does not accept orders for the selected destination. [DISMISSIBLE]'
};

export const userErrorMessages = {
  NO_TOKEN: 'Error: Uh oh! Something went wrong. Please try again or contact support.'
};

// export const dashboardErrorMessages = {
//   CANCELLED: 'This order has been cancelled.',
//   CANCEL_ERROR: 'There was a problem cancelling this order, please try again.',
//   RESEND_EMAIL_ERROR: 'There was a problem resending the payment email, please try again.',
//   TOO_LATE_TO_CANCEL: 'Too late to take action on this order.',
//   DETAILS_ERROR: 'There was a problem retrieving the customer\'s phone number and email. Please try again.',
//   NO_PHONE: 'There is no phone number associated with this customer\'s account.',
//   NO_EMAIL: 'There is no email address associated with this customer\'s account.',
//   ORDER_ERROR: 'There was a problem retrieving the order details for this order. Please try again.',
//   POS_ORDER: 'This order was placed on the Restaurant Point of Sale. Please make changes on the POS.',
//   ERROR_STATUS: 'Something went wrong with the submission of this order. Please re-enter the order to submit.',
// };

export const locationErrorMessages = {
  NO_LOCATIONS: 'No locations were found for this user. Please try again.',
  NO_ADP_LOCATIONS: 'No ADPs found for this restaurant. Please select a different Restaurant.'
};

export const dateTimeErrorMessages = {
  DATES_FAILURE:
    "Error: Uh oh! We're having trouble retrieving available dates. Please try again or contact support.",
  TIMES_FAILURE:
    "Error: Uh oh! We're having trouble retrieving available times. Please try again or contact support."
};

export const submitWarningErrorMessages = {
  CFA_GUEST:
    'Chick-fil-A One account was not selected. Please select an account on the Guest tab for the guest to earn points.',
  DELIVERY_MIN: 'Subtotal is less than minimum delivery order amount of $',
  PICKUP_MIN: 'Subtotal is less than minimum pickup order amount of $',
  ORDER_MAX: 'Subtotal is more than maximum order amount of $',
  VALID_ADDRESS: 'A valid address is required for delivery orders.'
};

export const paymentErrorMessages = {
  VALIDATE_ZIP:
    'Payment method must be validated by entering the zip code associated with the selected card.',
  PAYMENT_FAILURE: 'Payment failed to process. Tap Pay to retry or Cancel to cancel the order.',
  REFUND_SUCCESS: {
    type: 'error',
    message:
      'Uh oh! There was a problem submitting this order. The guests payment has been refunded. Please try submitting the order again.'
  },
  REFUND_FAILURE: {
    type: 'error',
    message: 'Uh oh! We had a problem submitting this order and a refund could not be processed.'
  }
};

export const editWarningMessage = 'Warning: You are editing an order for ';

export const printerErrorMessages = {
  PRINTER_NOT_CONNECTED: {
    type: 'warning',
    message:
      'Printer disconnected. Plug in a Printer or connect via bluetooth in your device’s settings.'
  },
  PRINTER_PAPER_EMPTY: {
    type: 'warning',
    message: 'Printer is out of paper. Please refill printer paper.'
  },
  PRINTER_PAPER_LOW: {
    type: 'warning',
    message: 'Printer paper is low, consider adding more paper soon.'
  },
  PRINTER_BATTERY_LOW: {
    type: 'warning',
    message: 'Printer battery is low, consider charging the battery soon.'
  },
  PAPER_AND_BATTERY_LOW: {
    type: 'warning',
    message:
      'Printer paper and battery levels are low or empty, consider charging the battery and adding more paper soon.'
  }
};

export const scanErrorMessages = {
  SCAN_ERROR: {
    type: 'warning',
    message: 'Uh oh! There was a problem attempting to scan. Please try scanning again.'
  },
  CUSTOMER_ERROR: {
    type: 'warning',
    message: "Uh oh! There was a problem reading the guests's QR code. Please try scanning again."
  },
  PERMISSIONS: {
    type: 'warning',
    message: 'Camera permission is required. To scan codes, allow permission to use the camera.'
  }
};
