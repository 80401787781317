import mockXhrPromise from './__mockXhr__';
import Constants from './constants';

/* global XMLHttpRequest */
/* istanbul ignore next reason: mock */
const xhrPromise = (url, data, headers, method = 'POST') => {
  if (Constants.MOCK_DXE) {
    const result = mockXhrPromise(url, data, headers, method);
    if (result) {
      // eslint-disable-next-line no-console
      console.log(`SERVICE Mocking request to ${url}`);
      return result;
    }

    // eslint-disable-next-line no-console
    console.log(`MAKING REAL REQUEST to ${url}`);
  }

  return new Promise(resolve => {
    const xhr = new XMLHttpRequest();
    xhr.addEventListener('readystatechange', () => {
      if (xhr.readyState !== 4) {
        return;
      }
      resolve(xhr);
    });
    xhr.open(method, url);
    if (headers) {
      headers.forEach(header => {
        xhr.setRequestHeader(header[0], header[1]);
      });
    }
    xhr.send(data);
  });
};

export default xhrPromise;
