import guestApiPromise from '../promise/guestApi';
import observify from '../util/observify';

export { mapUnboundPhoneCustomer } from '../promise/guestApi';

const getGuestSearchResultsFromApi = observify(guestApiPromise.getGuestSearchResultsFromApi);
const authenticateOnGuestBehalf = observify(guestApiPromise.authenticateOnGuestBehalf);
const lookupCustomerDetailsFromApi = observify(guestApiPromise.lookupCustomerDetailsFromApi);
const getPaymentFromApi = observify(guestApiPromise.getPaymentFromApi);
const lookupPaymentByAccountId = observify(guestApiPromise.lookupPaymentByAccountId);
const initiateGuestSession = observify(guestApiPromise.initiateGuestSession);
const validateZipFromApi = observify(guestApiPromise.validateZipFromApi);
const profileUpdateApi = observify(guestApiPromise.profileUpdateApi);
const getGuestPastOrders = observify(guestApiPromise.getGuestPastOrders);
const getFavoriteOrders = observify(guestApiPromise.getFavoriteOrders);
const addToFavorites = observify(guestApiPromise.addToFavorites);
const removeFromFavorites = observify(guestApiPromise.removeFromFavorites);
const updateFavoriteName = observify(guestApiPromise.updateFavoriteName);
const getGuestEmailSearchResultsFromApi = observify(
  guestApiPromise.getGuestEmailSearchResultsFromApi
);

const getGuestPhoneSearchResultsFromApi = observify(
  guestApiPromise.getGuestPhoneSearchResultsFromApi
);

const { refreshToken } = guestApiPromise;

export default {
  getGuestSearchResultsFromApi,
  getGuestEmailSearchResultsFromApi,
  getGuestPhoneSearchResultsFromApi,
  authenticateOnGuestBehalf,
  lookupCustomerDetailsFromApi,
  refreshToken,
  getPaymentFromApi,
  lookupPaymentByAccountId,
  initiateGuestSession,
  validateZipFromApi,
  profileUpdateApi,
  getGuestPastOrders,
  getFavoriteOrders,
  addToFavorites,
  removeFromFavorites,
  updateFavoriteName
};
