import Constants from '../util/constants';
import promiseRequestFactory from '../util/promiseRequestFactory';

export function getFeatureFlagsFromConfig(baseUrl) {
  const url = `${baseUrl}/1.0/feature-flags`;
  return promiseRequestFactory({
    url,
    method: 'GET',
    bugsnag: {
      errorClass: Constants.BUGSNAG_ERRORCLASS_OMT_CONFIG,
      context: 'Get Feature Flags From Config'
    }
  });
}

let featureFlags = {};

const FeatureFlagService = {
  initialize(baseUrl = Constants.URL.OMT_CONFIG) {
    featureFlags = {};
    return getFeatureFlagsFromConfig(baseUrl)
      .execute()
      .then(res => {
        featureFlags = res.allFlags;
        return res.allFlags;
      });
  },

  variation(featureFlag, defaultValue = false) {
    const value = featureFlags[featureFlag];
    if (value == null) {
      return defaultValue;
    }
    return value;
  }
};

export default FeatureFlagService;
