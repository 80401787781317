import { indexBy } from '../util/utils';
import Constants from '../util/constants';
import promiseRequestFactory from '../util/promiseRequestFactory';

function editExistingOrder(guestAccessToken, order) {
  const baseUrl = Constants.URL.ORDERS;
  const url = `${baseUrl}/1.0/${order.id}/resubmit`;

  return promiseRequestFactory({
    url,
    method: 'PUT',
    auth: {
      type: 'Bearer',
      apiKey: guestAccessToken
    },
    body: order,
    bugsnag: {
      errorClass: Constants.BUGSNAG_ERRORCLASS_ORDERS,
      context: 'Edit Existing Order'
    }
  });
}

function processMenu(res) {
  // TODO: remove when we want to implement packaged meals
  // const categories = filter(cat => cat.tag !== Constants.PACKAGED_MEALS, res.categories);

  // Create Hashmap of itemGroupIds and their coordinating itemGroups
  const itemGroups = indexBy(res.itemGroups, 'itemGroupId', { first: true });
  return { ...res, itemGroups };
}

function getAdpMenuFromApi(locationNumber, adpNumber) {
  const baseUrl = Constants.URL.ORDERS;
  const url = `${baseUrl}/locations/3.0/${locationNumber}/menu/client/adp/${adpNumber}`;
  const mapper = processMenu;
  return promiseRequestFactory({
    url,
    mapper,
    bugsnag: {
      errorClass: Constants.BUGSNAG_ERRORCLASS_ORDERS,
      context: 'Get ADP Menu'
    }
  });
}

function getMenuFromApi({ location, type, destination } = {}) {
  if (location == null || type == null) {
    return {
      execute: () => Promise.reject(new Error('location and type are required'))
    };
  }

  const baseUrl = Constants.URL.ORDERS;
  let url;
  url = `${baseUrl}/locations/3.1/${location}/menu/client/${type}`;
  if (destination) {
    url += `/${destination}`;
  }
  const mapper = processMenu;
  return promiseRequestFactory({
    url,
    mapper,
    bugsnag: {
      errorClass: Constants.BUGSNAG_ERRORCLASS_ORDERS,
      context: 'Get Menu'
    }
  });
}

function getNutrition() {
  const baseUrl = Constants.URL.ORDERS;
  const url = `${baseUrl}/2.0/menu/client/nutrition`;
  return promiseRequestFactory({
    url,
    mapper: res => res.items,
    bugsnag: {
      errorClass: Constants.BUGSNAG_ERRORCLASS_ORDERS,
      context: 'Get Nutrition'
    }
  });
}

function getOrderDates(guestAccessToken, orderId) {
  const baseUrl = Constants.URL.ORDERS;
  const url = `${baseUrl}/1.0/${orderId}/dates`;

  return promiseRequestFactory({
    url,
    method: 'GET',
    auth: {
      type: 'Bearer',
      apiKey: guestAccessToken
    },
    bugsnag: {
      errorClass: Constants.BUGSNAG_ERRORCLASS_ORDERS,
      context: 'Get Order Dates'
    }
  });
}

function getOrderTimes(guestAccessToken, orderId) {
  const baseUrl = Constants.URL.ORDERS;
  const url = `${baseUrl}/1.0/${orderId}/timeSlots`;
  const mapper = res => res.timeSlots;
  return promiseRequestFactory({
    url,
    method: 'GET',
    auth: {
      type: 'Bearer',
      apiKey: guestAccessToken
    },
    mapper,
    bugsnag: {
      errorClass: Constants.BUGSNAG_ERRORCLASS_ORDERS,
      context: 'Get Order Times'
    }
  });
}

function initiateOrder(guestAccessToken, order) {
  const baseUrl = Constants.URL.ORDERS;
  const url = `${baseUrl}/1.0`;
  return promiseRequestFactory({
    url,
    method: 'POST',
    auth: {
      type: 'Bearer',
      apiKey: guestAccessToken
    },
    body: order,
    bugsnag: {
      errorClass: Constants.BUGSNAG_ERRORCLASS_ORDERS,
      context: 'Initiate Order'
    }
  });
}

function requestPaymentApi(guestAccessToken, orderId) {
  const baseUrl = Constants.URL.ORDERS;
  const url = `${baseUrl}/1.0/${orderId}/paymentRequest`;
  return promiseRequestFactory({
    url,
    method: 'POST',
    auth: {
      type: 'Bearer',
      apiKey: guestAccessToken
    },
    bugsnag: {
      errorClass: Constants.BUGSNAG_ERRORCLASS_ORDERS,
      context: 'Request Payment Api'
    }
  });
}

function updateOrder(guestAccessToken, order) {
  const baseUrl = Constants.URL.ORDERS;
  const url = `${baseUrl}/1.0/${order.id}`;

  return promiseRequestFactory({
    url,
    method: 'PUT',
    auth: {
      type: 'Bearer',
      apiKey: guestAccessToken
    },
    body: order,
    bugsnag: {
      errorClass: Constants.BUGSNAG_ERRORCLASS_ORDERS,
      context: 'Update order'
    }
  });
}

function autocompleteAddressFromApi(address, guestAccessToken) {
  const baseUrl = Constants.URL.LOCATIONS;
  const validAddress = encodeURIComponent(address);
  const url = `${baseUrl}/locations/2.0/address/autocomplete?address=${validAddress}`;
  return promiseRequestFactory({
    url,
    method: 'GET',
    auth: {
      type: 'Bearer',
      apiKey: guestAccessToken
    },
    bugsnag: {
      errorClass: Constants.BUGSNAG_ERRORCLASS_LOCATIONS,
      context: 'Autocomplete address'
    }
  });
}

function validateAddressFromApi(address, guestAccessToken) {
  const baseUrl = Constants.URL.LOCATIONS;
  const url = `${baseUrl}/locations/2.0/address/validate`;
  return promiseRequestFactory({
    url,
    method: 'POST',
    auth: {
      type: 'Bearer',
      apiKey: guestAccessToken
    },
    body: address,
    bugsnag: {
      errorClass: Constants.BUGSNAG_ERRORCLASS_LOCATIONS,
      context: 'Validate address'
    }
  });
}

function validateDeliveryRange(address, guestAccessToken) {
  const baseUrl = Constants.URL.LOCATIONS;
  const url = `${baseUrl}/locations/4.0/catering/delivery`;
  return promiseRequestFactory({
    url,
    method: 'POST',
    auth: {
      type: 'Bearer',
      apiKey: guestAccessToken
    },
    body: address,
    bugsnag: {
      errorClass: Constants.BUGSNAG_ERRORCLASS_LOCATIONS,
      context: 'Validate delivery range'
    }
  });
}

export default {
  editExistingOrder,
  getAdpMenuFromApi,
  getMenuFromApi,
  getNutrition,
  getOrderDates,
  getOrderTimes,
  initiateOrder,
  requestPaymentApi,
  updateOrder,
  autocompleteAddressFromApi,
  validateAddressFromApi,
  validateDeliveryRange
};
