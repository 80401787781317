import Constants from '../util/constants';
import promiseRequestFactory from '../util/promiseRequestFactory';

function checkMessagesOnLoad(cfaAccessToken, type) {
  const baseUrl = Constants.URL.PROFILE;
  const url = `${baseUrl}/messagesAcknowledgements/1.0/${type}/acknowledgement`;
  return promiseRequestFactory({
    url,
    method: 'GET',
    maxRetries: 0,
    auth: {
      type: 'JWTBearer',
      apiKey: cfaAccessToken
    },
    bugsnag: {
      errorClass: Constants.BUGSNAG_ERRORCLASS_PROFILE,
      context: `Check ${type} Messages Acknowledgement`
    }
  });
}

function getMessageText(cfaAccessToken, type) {
  const baseUrl = Constants.URL.PROFILE;
  const url = `${baseUrl}/messagesAcknowledgements/1.0/${type}`;
  return promiseRequestFactory({
    url,
    method: 'GET',
    maxRetries: 0,
    auth: {
      type: 'JWTBearer',
      apiKey: cfaAccessToken
    },
    bugsnag: {
      errorClass: Constants.BUGSNAG_ERRORCLASS_PROFILE,
      context: `Get ${type} Message Text`
    }
  });
}

function addUserToMessageAcknowledgement(cfaAccessToken, type) {
  const baseUrl = Constants.URL.PROFILE;
  const url = `${baseUrl}/messagesAcknowledgements/1.0/${type}/acknowledgement`;
  return promiseRequestFactory({
    url,
    method: 'POST',
    maxRetries: 0,
    auth: {
      type: 'JWTBearer',
      apiKey: cfaAccessToken
    },
    bugsnag: {
      errorClass: Constants.BUGSNAG_ERRORCLASS_PROFILE,
      context: `Add User To ${type} Message Acknowledgement`
    }
  });
}

export default {
  checkMessagesOnLoad,
  getMessageText,
  addUserToMessageAcknowledgement
};
