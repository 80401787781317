/* istanbul ignore file reason: these are mocks */
import Constants from '../constants';

let requestMocks;

export const mockXhr = {
  setMocks(newMockConfig) {
    requestMocks = newMockConfig;
  }
};

const getMockResponse = (url, data, headers, method) => {
  for (let i = 0; i < requestMocks.length; i += 1) {
    const requestMock = requestMocks[i];
    if (requestMock.matcher(url, data, headers, method)) {
      let errors = [];
      if (requestMock.validate) {
        errors = requestMock.validate(url, data, headers, method);
      }

      if (errors.length !== 0) {
        const message = `Request for ${url} failed mock validation: ${errors.join(', ')}`;
        // eslint-disable-next-line no-console
        console.error(message);
        throw new Error(message);
      }

      if (requestMock.throwError) {
        throw requestMock.throwError();
      }

      const status = requestMock.status ? requestMock.status(url, data, headers, method) : 200;
      const responseText = requestMock.responseText(url, data, headers, method);

      return { status, responseText };
    }
  }

  if (Constants.MOCK_DXE === 'require') {
    const message = `Request for ${url} was not mocked.`;
    // eslint-disable-next-line no-console
    console.error(message);
    throw new Error(message);
  } else {
    return null;
  }
};

const mockXhrPromise = (url, data, headers, method) => {
  try {
    const mockResponse = getMockResponse(url, data, headers, method);
    if (mockResponse) {
      return new Promise(resolve => {
        setTimeout(() => {
          resolve(mockResponse);
        }, 200);
      });
    }
    return null;
  } catch (e) {
    return new Promise(resolve => {
      setTimeout(() => {
        resolve({
          status: 500,
          responseText: e.message
        });
      }, 200);
    });
  }
};

export default mockXhrPromise;
