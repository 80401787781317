import { getConfig as Config } from './config';

export default class Constants {
  static get DEV() {
    return 'dev';
  }

  static get QA() {
    return 'qa';
  }

  static get PROD() {
    return 'prod';
  }

  static get DEFAULT_ENVIRONMENT() {
    return this.QA;
  }

  static get ENVIRONMENT_DATA() {
    const environment = Config().ENVIRONMENT || this.DEFAULT_ENVIRONMENT;
    const pilot = Config().IS_PILOT || false;
    return { ENV: environment, IS_PILOT: pilot };
  }

  static get ENV() {
    return this.ENVIRONMENT_DATA.ENV;
  }

  static get URL() {
    let pilot = '';
    if (this.ENVIRONMENT_DATA.IS_PILOT) {
      pilot = '-pilot';
    }

    return {
      ORDERS: `https://order${pilot}.${this.DXE_SERVICES_BASE}/orders`,
      PROFILE: `https://profile${pilot}.${this.DXE_SERVICES_BASE}`,
      D2C: `https://d2c${pilot}.${this.D2C_SERVICES_BASE}`,
      PAYMENT: `https://gc${pilot}.${this.DXE_SERVICES_BASE}`,
      LOCATIONS: `https://location${pilot}.${this.DXE_SERVICES_BASE}`,
      OMT_CONFIG: `https://order${pilot}.${this.DXE_SERVICES_BASE}/omt/config`,
      LOYALTY: `https://pos${pilot}.${this.DXE_SERVICES_BASE}/loyalty`
    };
  }

  static get DXE_SERVICES_BASE() {
    if (this.ENV === this.PROD) {
      return Config().DXE_SERVICES_BASE;
    }
    return `api.${this.QA}.crndev.chick-fil-a.com`;
  }

  static get D2C_SERVICES_BASE() {
    if (this.ENV === this.PROD) {
      return Config().D2C_SERVICES_BASE;
    }
    return `api.${this.QA}.crndev.chick-fil-a.com`;
  }

  static get TOKEN_TYPES() {
    return {
      GUEST_TYPE: 'GUEST_TYPE',
      MASQUERADE_TYPE: 'MASQUERADE_TYPE'
    };
  }

  static get MOCK_DXE() {
    return Config().MOCK_DXE;
  }

  static get BUGSNAG_ERRORCLASS_ANALYTICS() {
    return 'Analytics API';
  }

  static get BUGSNAG_ERRORCLASS_PAYMENTS() {
    return 'Payments API';
  }

  static get BUGSNAG_ERRORCLASS_ORDERS() {
    return 'Orders API';
  }

  static get BUGSNAG_ERRORCLASS_PROFILE() {
    return 'Profile API';
  }

  static get BUGSNAG_ERRORCLASS_D2C() {
    return 'D2C API';
  }

  static get BUGSNAG_ERRORCLASS_LOCATIONS() {
    return 'Locations API';
  }

  static get BUGSNAG_ERRORCLASS_LOYALTY() {
    return 'Loyalty API';
  }

  static get BUGSNAG_ERRORCLASS_OMT_CONFIG() {
    return 'OMT Config API';
  }
}
