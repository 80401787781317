import Constants from '../util/constants';
import promiseRequestFactory from '../util/promiseRequestFactory';
import normalizePhone from '../util/normalizePhone';

function mapUnboundCustomer(unboundCustomer) {
  const {
    name: { givenName, familyName },
    phoneNumbers,
    email,
    uid,
    loyaltyCardNumber
  } = unboundCustomer;

  let phone = '';
  if (phoneNumbers && phoneNumbers[0]) {
    phone = normalizePhone(phoneNumbers[0].value);
  }

  return {
    cfaId: uid,
    firstName: givenName,
    lastName: familyName,
    phone,
    email,
    loyaltyCardNumber
  };
}

function mapCustomerEmailSearchResults(unboundCustomer) {
  const { givenName, familyName, phoneNumber, emailAddress, id } = unboundCustomer;

  let phone = '';
  if (phoneNumber) {
    phone = normalizePhone(phoneNumber);
  }

  return {
    cfaId: id,
    firstName: givenName,
    lastName: familyName,
    phone,
    email: emailAddress
  };
}

export function mapUnboundPhoneCustomer(unboundCustomer) {
  const {
    givenName,
    familyName,
    telephoneNumber,
    Mobile,
    primaryEmail,
    uid,
    loyaltyCardNumber
  } = unboundCustomer;
  const phone = normalizePhone(telephoneNumber || Mobile);

  return {
    cfaId: uid,
    firstName: givenName,
    lastName: familyName,
    phone,
    email: primaryEmail,
    loyaltyCardNumber
  };
}

function getGuestSearchResultsFromApi(
  firstName,
  lastName,
  cfaAccessToken,
  locationNumber,
  options = {}
) {
  const baseUrl = Constants.URL.PROFILE;
  const url = `${baseUrl}/customers/locations/1.0/${locationNumber}/search`;

  const body = { firstName, lastName };
  if (options.cfaOneOnly) {
    body.cfaOneOnly = 'true';
  }

  const mapper = customers => {
    if (customers && customers.length) {
      return customers.map(mapUnboundCustomer);
    }
    return [];
  };
  return promiseRequestFactory({
    url,
    method: 'POST',
    auth: {
      type: 'JWTBearer',
      apiKey: cfaAccessToken
    },
    body,
    mapper,
    bugsnag: {
      errorClass: Constants.BUGSNAG_ERRORCLASS_PROFILE,
      context: 'Name Search Results'
    }
  });
}

function getGuestEmailSearchResultsFromApi(emailAddress, cfaAccessToken) {
  const baseUrl = Constants.URL.D2C;
  const url = `${baseUrl}/cfa.d2c.customer.v1.CustomerService/FindCustomersByEmailAddress`;

  const mapper = res => {
    if (res.customers && res.customers.length) {
      return res.customers.map(mapCustomerEmailSearchResults);
    }
    return [];
  };
  return promiseRequestFactory({
    url,
    method: 'POST',
    auth: {
      type: 'JWTBearer',
      apiKey: cfaAccessToken
    },
    body: { emailAddress },
    mapper,
    bugsnag: {
      errorClass: Constants.BUGSNAG_ERRORCLASS_D2C,
      context: 'Email Search Results'
    }
  });
}

function getGuestPhoneSearchResultsFromApi(phone, cfaAccessToken) {
  const baseUrl = Constants.URL.PROFILE;
  const url = `${baseUrl}/users/2.0/phone?phone=${phone}`;

  const mapper = res => {
    if (res.userProfiles && res.userProfiles.length) {
      return res.userProfiles.map(mapUnboundPhoneCustomer);
    }
    return [];
  };
  return promiseRequestFactory({
    url,
    method: 'GET',
    auth: {
      type: 'JWTBearer',
      apiKey: cfaAccessToken
    },
    mapper,
    bugsnag: {
      errorClass: Constants.BUGSNAG_ERRORCLASS_PROFILE,
      context: 'Phone Search Results'
    }
  });
}

function authenticateOnGuestBehalf(cfaId, cfaAccessToken) {
  const baseUrl = Constants.URL.PROFILE;
  const url = `${baseUrl}/users/3.1/${cfaId}/masqueradeSession`;
  const body = {
    client_id: 'ooe_masquerade',
    scope: '/users/me+/orders/me+/giftcard/limited/me+/bypassBusinessRules'
  };

  const mapper = data => ({
    accessToken: data.access_token,
    refreshToken: data.refresh_token,
    expiresIn: data.expires_in,
    expiryTimeMs: Date.now() + data.expires_in * 1000
  });
  return promiseRequestFactory({
    url,
    method: 'POST',
    auth: {
      type: 'JWTBearer',
      apiKey: cfaAccessToken
    },
    body,
    mapper,
    bugsnag: {
      errorClass: Constants.BUGSNAG_ERRORCLASS_PROFILE,
      context: 'Masquerade Session'
    }
  });
}

function lookupCustomerDetailsFromApi(qrString, cfaAccessToken, locationNumber, externalOrderId) {
  const baseUrl = Constants.URL.LOYALTY;
  const url = `${baseUrl}/2.0/pos/licensee/members/profile`;
  const body = {
    qrString,
    getCustomerInfo: true,
    getRewards: true,
    getBalance: true,
    locationNumber,
    externalOrderId,
    statusList: 'AVAILABLE,USED,FUTURE,EXPIRED'
  };
  return promiseRequestFactory({
    url,
    method: 'POST',
    auth: {
      type: 'JWTBearer',
      apiKey: cfaAccessToken
    },
    body,
    bugsnag: {
      errorClass: Constants.BUGSNAG_ERRORCLASS_LOYALTY,
      context: 'Lookup Customer Details'
    }
  });
}

function refreshToken(guestRefreshToken) {
  const baseUrl = Constants.URL.PROFILE;
  const url = `${baseUrl}/oauth2/accesstoken`;

  const form = new FormData();
  form.append('grant_type', 'refresh_token');
  form.append('refresh_token', guestRefreshToken);
  form.append('client_id', 'ooe_masquerade');
  const mapper = data => ({
    accessToken: data.access_token,
    expiresIn: data.expires_in,
    expiryTimeMs: Date.now() + data.expires_in * 1000
  });

  const method = 'POST';
  const bugsnag = {
    errorClass: Constants.BUGSNAG_ERRORCLASS_PROFILE,
    context: 'Refresh Guest Session Token'
  };

  return promiseRequestFactory({
    method,
    url,
    body: form,
    form: true,
    mapper,
    bugsnag
  }).execute();
}

function getPaymentFromApi(guestAccessToken) {
  const baseUrl = Constants.URL.PAYMENT;
  const url = `${baseUrl}/accounts/1.0/me`;
  return promiseRequestFactory({
    url,
    method: 'GET',
    auth: {
      type: 'Bearer',
      apiKey: guestAccessToken
    },
    bugsnag: {
      errorClass: Constants.BUGSNAG_ERRORCLASS_PAYMENTS,
      context: 'Get Payment'
    }
  });
}

function lookupPaymentByAccountId(accountId, guestAccessToken) {
  const baseUrl = Constants.URL.PAYMENT;
  const url = `${baseUrl}/accounts/1.0/me/${accountId}`;
  return promiseRequestFactory({
    url,
    method: 'GET',
    auth: {
      type: 'Bearer',
      apiKey: guestAccessToken
    },
    bugsnag: {
      errorClass: Constants.BUGSNAG_ERRORCLASS_PAYMENTS,
      context: 'Lookup Payment By Account Id'
    }
  });
}

function initiateGuestSession(cfaAccessToken) {
  // console.log('SHARED initiateGuestSession YALL')
  const baseUrl = Constants.URL.PROFILE;
  const url = `${baseUrl}/users/3.0/guest/session`;

  const mapper = data => ({
    accessToken: data.access_token,
    refreshToken: data.refresh_token,
    expiresIn: data.expires_in,
    expiryTimeMs: Date.now() + data.expires_in * 1000
  });
  return promiseRequestFactory({
    url,
    method: 'POST',
    auth: {
      type: 'JWTBearer',
      apiKey: cfaAccessToken
    },
    mapper,
    bugsnag: {
      errorClass: Constants.BUGSNAG_ERRORCLASS_PROFILE,
      context: 'Initiate Guest Session'
    }
  });
}

function validateZipFromApi({ zip, card = {}, apiKey } = {}) {
  const baseUrl = Constants.URL.PAYMENT;
  const url = `${baseUrl}/accounts/1.0/me/search`;
  return promiseRequestFactory({
    url,
    method: 'POST',
    auth: {
      type: 'Bearer',
      apiKey
    },
    body: {
      cardAlias: card.accountDisplay,
      cardType: card.cardType,
      zipCode: zip
    },
    bugsnag: {
      errorClass: Constants.BUGSNAG_ERRORCLASS_PAYMENTS,
      context: 'Validate Zipcode'
    }
  });
}

function profileUpdateApi(guestAccessToken, guest) {
  const baseUrl = Constants.URL.PROFILE;
  const url = `${baseUrl}/users/2.0/me`;
  return promiseRequestFactory({
    url,
    method: 'PATCH',
    auth: {
      type: 'Bearer',
      apiKey: guestAccessToken
    },
    body: guest,
    bugsnag: {
      errorClass: Constants.BUGSNAG_ERRORCLASS_PROFILE,
      context: 'Update Guest Profile'
    }
  });
}

function getGuestPastOrders(guestAccessToken, numToLoad = 3) {
  const baseUrl = Constants.URL.ORDERS;
  const url = `${baseUrl}/users/1.1/me/history/catering?maximumCount=${numToLoad}`;
  const mapper = pastOrders =>
    pastOrders.map(order => ({
      ...order,
      lineItems: order.lineItems.map(item => ({
        tag: item.itemTag,
        quantity: item.quantity,
        name: item.name,
        comboItems: item.comboItems,
        modifiers: item.modifiers
      }))
    }));
  return promiseRequestFactory({
    url,
    method: 'GET',
    auth: {
      type: 'Bearer',
      apiKey: guestAccessToken
    },
    mapper,
    bugsnag: {
      errorClass: Constants.BUGSNAG_ERRORCLASS_ORDERS,
      context: 'Get Guest Past Orders'
    }
  });
}

function getFavoriteOrders(guestAccessToken, locationNumber, destination, isCatering) {
  const baseUrl = Constants.URL.ORDERS;
  const url = `${baseUrl}/1.1/users/me/favorites?locationNumber=${locationNumber}&destination=${destination}&isCatering=${isCatering}`;
  return promiseRequestFactory({
    url,
    method: 'GET',
    auth: {
      type: 'Bearer',
      apiKey: guestAccessToken
    },
    bugsnag: {
      errorClass: Constants.BUGSNAG_ERRORCLASS_ORDERS,
      context: 'Get Favorite Orders'
    }
  });
}

function addToFavorites(orderId, name, guestAccessToken) {
  const baseUrl = Constants.URL.ORDERS;
  const url = `${baseUrl}/1.1/users/me/favorites`;
  return promiseRequestFactory({
    url,
    method: 'POST',
    auth: {
      type: 'Bearer',
      apiKey: guestAccessToken
    },
    body: {
      orderId,
      name
    },
    bugsnag: {
      errorClass: Constants.BUGSNAG_ERRORCLASS_ORDERS,
      context: 'Add To Favorites'
    }
  });
}

function removeFromFavorites(favoriteOrderId, guestAccessToken) {
  const baseUrl = Constants.URL.ORDERS;
  const url = `${baseUrl}/1.1/users/me/favorites/${favoriteOrderId}`;
  return promiseRequestFactory({
    url,
    method: 'DELETE',
    auth: {
      type: 'Bearer',
      apiKey: guestAccessToken
    },
    bugsnag: {
      errorClass: Constants.BUGSNAG_ERRORCLASS_ORDERS,
      context: 'Remove From Favorites'
    }
  });
}

function updateFavoriteName(favoriteOrderId, favoriteName, guestAccessToken) {
  const baseUrl = Constants.URL.ORDERS;
  const url = `${baseUrl}/1.1/users/me/favorites/${favoriteOrderId}/name?name=${favoriteName}`;
  return promiseRequestFactory({
    url,
    method: 'PUT',
    auth: {
      type: 'Bearer',
      apiKey: guestAccessToken
    },
    bugsnag: {
      errorClass: Constants.BUGSNAG_ERRORCLASS_ORDERS,
      context: 'Update Favorite Name'
    }
  });
}

export default {
  getGuestSearchResultsFromApi,
  getGuestEmailSearchResultsFromApi,
  getGuestPhoneSearchResultsFromApi,
  authenticateOnGuestBehalf,
  lookupCustomerDetailsFromApi,
  refreshToken,
  getPaymentFromApi,
  lookupPaymentByAccountId,
  initiateGuestSession,
  validateZipFromApi,
  profileUpdateApi,
  getGuestPastOrders,
  getFavoriteOrders,
  addToFavorites,
  removeFromFavorites,
  updateFavoriteName
};
