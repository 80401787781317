import handleErrors, { handleFetchFail } from './handleErrors';
import { userErrorMessages } from './customerErrorMessages';
import xhrPromise from './xhrPromise';
import bugsnag from './bugsnagIntegration';

function getBody({ method, form, body }) {
  if (method === 'PUT' || method === 'POST' || method === 'PATCH') {
    if (form) {
      return body;
    }
    return JSON.stringify(body);
  }
  return null;
}

const getAuthorizationHeader = auth => `${auth.type} ${auth.apiKey}`;

function getHeaders({ auth, form }) {
  const headers = {};

  if (!form) {
    headers.Accept = 'application/json';
    headers['Content-Type'] = 'application/json';
  }

  if (auth) {
    headers.Authorization = getAuthorizationHeader(auth);
  }

  return headers;
}

export class ApiCall {
  constructor(props) {
    // eslint-disable-next-line no-shadow
    const { method, url, auth, form, maxRetries = 5, mapper, bugsnag, body } = props;
    this.headers = getHeaders({ auth, form });
    this.body = getBody({ method, form, body });
    this.auth = auth || {};
    this.bugsnag = bugsnag || {};
    this.method = method || 'GET';
    this.url = url;
    this.numRetries = 0;
    this.maxRetries = maxRetries;
    this.mapper = mapper;
    this.execute = this.execute.bind(this);
    this.retry = this.retry.bind(this);
  }

  execute() {
    if (this.auth.type && !this.auth.apiKey) {
      return Promise.reject(userErrorMessages.NO_TOKEN);
    }

    const breadcrumb = `${this.bugsnag.breadcrumb ||
      this.bugsnag.context ||
      'ApiCall Request'} [wps]`;

    bugsnag.getLeaveBreadcrumb()(breadcrumb, {
      url: this.url,
      method: this.method,
      body: this.body,
      context: this.bugsnag.context,
      info: this.bugsnag.info
    });

    let promiseChain = this._fetchCall()
      .catch(handleFetchFail)
      .then(xhr => handleErrors(xhr, this._getBugsnagDetails()));

    if (this.mapper) {
      promiseChain = promiseChain.then(this.mapper);
    }
    return promiseChain;
  }

  retryLimitReached() {
    return this.numRetries >= this.maxRetries;
  }

  retry(tokens) {
    this._appendNewToken(tokens);
    this.numRetries += 1;
    return this.execute();
  }

  _fetchCall() {
    const headerPairs = Object.entries(this.headers);
    return xhrPromise(this.url, this.body, headerPairs, this.method);
  }

  _appendNewToken(tokens) {
    if (this.auth.type === 'Bearer') {
      this.auth.apiKey = tokens.apiKey;
    } else if (this.auth.type === 'JWTBearer') {
      this.auth.apiKey = tokens.accessToken;
    }
    this.headers.Authorization = getAuthorizationHeader(this.auth);
  }

  _getBugsnagDetails() {
    return {
      errorClass: this.bugsnag.errorClass,
      debug: {
        context: this.bugsnag.context,
        url: this.url,
        method: this.method,
        body: this.body,
        info: this.bugsnag.info
      }
    };
  }
}

function request(props) {
  return new ApiCall(props);
}

export default request;
