import messagesApiPromise from '../promise/messagesApi';
import observify from '../util/observify';

const checkMessagesOnLoad = observify(messagesApiPromise.checkMessagesOnLoad);
const getMessageText = observify(messagesApiPromise.getMessageText);
const addUserToMessageAcknowledgement = observify(
  messagesApiPromise.addUserToMessageAcknowledgement
);

export default {
  checkMessagesOnLoad,
  getMessageText,
  addUserToMessageAcknowledgement
};
