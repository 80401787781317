import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/fromPromise';
import FeatureFlagServicePromise from '../promise/FeatureFlagService';

const FeatureFlagService = {
  initialize: (...args) => Observable.fromPromise(FeatureFlagServicePromise.initialize(...args)),
  variation: (...args) => FeatureFlagServicePromise.variation(...args)
};

export default FeatureFlagService;
