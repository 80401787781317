let notifyBugsnag;
let leaveBreadcrumb;

const noop = () => {};

const getNotifyBugsnag = () => notifyBugsnag || noop;
const setNotifyBugsnag = newNotifyBugsnag => {
  notifyBugsnag = newNotifyBugsnag;
};

const getLeaveBreadcrumb = () => leaveBreadcrumb || noop;
const setLeaveBreadcrumb = newLeaveBreadcrumb => {
  leaveBreadcrumb = newLeaveBreadcrumb;
};

export default {
  setNotifyBugsnag,
  getNotifyBugsnag,
  getLeaveBreadcrumb,
  setLeaveBreadcrumb
};
