/* eslint-disable max-classes-per-file */
import { statusCodes } from './customerErrorMessages';
import bugsnag from './bugsnagIntegration';

export class ExtendableError extends Error {
  constructor(message) {
    super(message);
    this.name = this.constructor.name;
    if (typeof Error.captureStackTrace === 'function') {
      Error.captureStackTrace(this, this.constructor);
    } else {
      this.stack = new Error(message).stack;
    }
  }
}

export class NeedsRefreshError extends ExtendableError {
  constructor(message) {
    super(message); // (1)
    this.name = 'NeedsRefreshError';
    this.needsRefresh = true;
  }
}
export class MappedError extends ExtendableError {
  constructor(message, type) {
    super(message); // (1)
    this.name = type;
  }
}

export default function handleErrors(xhr, bugsnagDetails) {
  return new Promise((resolve, reject) => {
    let response = {};
    if (xhr.responseText && xhr.responseText.length) {
      if (xhr.responseText.indexOf('<body') > -1) {
        // Parse main error text
        const responseHtml = xhr.responseText.match(/.*<body.*>([\s\S]*)<\/body>.*/);
        if (responseHtml) {
          try {
            // Remove HTML tags and newlines
            response = responseHtml[1].replace(/(<([^>]+)>)/gi, '').replace(/\r?\n|\r/g, '');
            return reject(response);
          } catch (e) {
            return reject(xhr.responseText);
          }
        }
      }
      try {
        response = JSON.parse(xhr.responseText);
      } catch (e) {
        return reject(xhr.responseText);
      }
    }
    if (xhr.status >= 200 && xhr.status < 300) {
      return resolve(response);
    }
    let customerErr = '';
    let errorCode;
    if (response.message || response.statusMessage || response.statusCode) {
      customerErr = response.statusMessage;
      errorCode = response.statusCode;
    }

    bugsnag.getNotifyBugsnag()(bugsnagDetails.errorClass, {
      ...bugsnagDetails.debug,
      errorMessage: customerErr,
      response,
      responseData: response,
      httpStatus: xhr.status,
      httpStatusText: xhr.statusText
    });
    if (xhr.status === 401) {
      // eslint-disable-next-line
      return reject(new NeedsRefreshError(customerErr));
    }
    /* istanbul ignore if */
    if (statusCodes[errorCode]) {
      customerErr = statusCodes[errorCode];
      const { message, type } = customerErr;
      if (errorCode === 1378) {
        const { cfaError } = response;
        const { userMessage } = cfaError;
        return reject(new MappedError(userMessage, type));
      }
      return reject(new MappedError(message, type));
    }
    if (xhr.status === 0) {
      return reject(
        new Error('Request failed for unknown reason. Please check your console for details.')
      );
    }
    return reject(new Error(customerErr));
  });
}

export function handleFetchFail() {
  return new Promise(() => {
    const { message, type } = statusCodes[1301];
    throw new MappedError(message, type);
  });
}
/* eslint-enable  */
