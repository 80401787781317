import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/fromPromise';
import { ApiCall } from './promiseRequestFactory';

export class ObservableApiCall extends ApiCall {
  execute() {
    return Observable.fromPromise(super.execute());
  }
}

function request(props) {
  return new ObservableApiCall(props);
}

export default request;
